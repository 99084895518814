import styled, { css } from "styled-components"
import { media } from "src/styles/breakpoints"

export default styled.div(({ narrow, wide }) => {
  return css`
    width: 100%;
    max-width: ${(p) =>
      p.theme[
        p.narrow
          ? "narrowContainerWidth"
          : p.wide
          ? "wideContainerWidth"
          : "containerWidth"
      ] +
      p.theme.sidePadding.desktop * 2}px;
    padding-left: ${(p) => p.theme.sidePadding.phone}px;
    padding-right: ${(p) => p.theme.sidePadding.phone}px;
    margin-left: auto;
    margin-right: auto;

    ${media.tablet} {
      padding-left: ${(p) => p.theme.sidePadding.tablet}px;
      padding-right: ${(p) => p.theme.sidePadding.tablet}px;
    }

    ${media.desktop} {
      padding-left: ${(p) => p.theme.sidePadding.desktop}px;
      padding-right: ${(p) => p.theme.sidePadding.desktop}px;
    }
  `
})
