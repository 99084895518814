import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import BaseIcon from "src/components/Base/BaseIcon"

const CookieAlert = () => {
  const [hasRead, setHasRead] = useState(true)
  const wrapper = useRef()
  const storageItemName = "cookieInfo3"

  function markRead() {
    setHasRead(true)
    if (typeof localStorage !== "undefined") {
      localStorage.setItem(storageItemName, "read")
    }
  }

  useEffect(() => {
    setHasRead(!!localStorage.getItem(storageItemName))

    setTimeout(() => {
      if (wrapper && wrapper.current) {
        wrapper.current.classList.add("-init")
      }
    }, 3000)
  }, [])

  return (
    <>
      {!hasRead && (
        <Wrapper ref={wrapper}>
          <Message>
            We use cookies to improve your browsing experience. Find out more in
            our <StyledLink to="/privacy-policy">Privacy policy</StyledLink>.
          </Message>
          <Button onClick={markRead}>
            <BaseIcon name="x" source="feather" />
          </Button>
        </Wrapper>
      )}
    </>
  )
}

const Wrapper = styled.div`
  visibility: hidden;
  opacity: 0;
  max-width: 710px;
  margin: 0 auto;
  padding: 18px 60px 19px 30px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  left: 10px;
  z-index: 5;
  transform: translateY(100px);
  background: ${(p) => p.theme.color.primary};
  background: rgba(62, 61, 78, 0.95);
  border-radius: 90px;
  box-shadow: 0 20px 40px rgba(0, 11, 33, 0.08);
  transition: transform 0.5s;
  transition: 1s ${(p) => p.theme.easing.easeOutExpo};
  transition-property: visibility, opacity, transform;

  &.-init {
    visibility: visible;
    opacity: 1;
    transform: none;
  }

  ${media.tablet} {
    bottom: 20px;
  }
`

const Message = styled.p`
  font-size: 11px;
  color: #fff;

  ${media.tablet} {
    font-size: 14px;
  }
`

const StyledLink = styled(Link)`
  display: inline-block;
  opacity: 0.8;

  &,
  &:hover {
    text-decoration: underline;
  }
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  color: #fff;
  transition: 0.15s;

  &:hover {
    opacity: 0.7;
  }
`

export default CookieAlert
