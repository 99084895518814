const WebFont = require("webfontloader")

export const onClientEntry = async () => {
  // Intersection observer polyfill
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

export const onInitialClientRender = async () => {
  // load web fonts
  // critial first
  WebFont.load({
    custom: {
      families: ["Poppins wf:n2,n5"],
      urls: ["/fonts/fonts-critical.css"],
    },
  })
  // then secondary
  setTimeout(() => {
    WebFont.load({
      custom: {
        families: [
          "Poppins wf:n4,n7",
          "Frank Ruhl Libre wf:n4,n6,n7",
          "Roboto Mono wf:n4",
          "Acumin Pro ExtraCondensed wf:n4",
        ],
        urls: ["/fonts/fonts-secondary.css"],
      },
    })
  }, 1300)
}
