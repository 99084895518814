import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledBanner = styled.div`
    background: linear-gradient(-20deg,#179cc2,#37d0cd);
    color: white;
    text-align: center;
    padding: 1em 0;
    width: 100%;
    margin-bottom: 1.5em; 
    cursor: pointer;
`;

const BannerLink = styled.a`
    color: white;
    text-decoration: none;
    display: block;
`;

const Banner = ({ link, text }) => {
    return (
        <StyledBanner>
            <BannerLink href={link} target="_blank" rel="noopener noreferrer">
                {text}
            </BannerLink>
        </StyledBanner>
    );
};

Banner.propTypes = {
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};

export default Banner;