import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"

const FooterNavigation = () => {
  const { allStrapiMenu } = useStaticQuery(
    graphql`
      query {
        allStrapiMenu(filter: { parent_menu_dropdown: { id: { eq: null } } }) {
          nodes {
            title
            slug
          }
        }
      }
    `
  )

  return (
    <Wrapper>
      <Menu>
        {allStrapiMenu.nodes.map(({ title, slug }, index) => (
          <MenuItem key={index}>
            <MenuLink to={`/${slug}`}>{title}</MenuLink>
          </MenuItem>
        ))}
      </Menu>
    </Wrapper>
  )
}

const Wrapper = styled.nav`
  padding-bottom: 32px;
`

const Menu = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: 500;
  font-size: 15px;
  line-height: 2.4;
  color: #000;

  ${media.tablet} {
    margin-bottom: 22px;
  }
`

const MenuItem = styled.li`
  width: 34%;

  @media (min-width: 768px) and (max-width: 1023px) {
    &:nth-child(2n + 1) {
      position: relative;
      left: 70px;
    }
  }

  @media (max-width: 767px) {
    &:nth-child(2n) {
      text-align: right;
    }
  }
`

const MenuLink = styled(Link)`
  ${media.tablet} {
    display: inline-block;
    position: relative;

    &::before {
      content: "";
      width: 0;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 2px;
      background: ${(p) => p.theme.color.primary};
      transition: width 0.25s;
    }

    &:hover {
      &::before {
        width: 20px;
      }
    }
  }
`

export default FooterNavigation
