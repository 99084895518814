const colors = {
  turquoise: "#52bbb3",
  plum: "#3e3d4e",
  lightPlum: "#52525B",
  darkPlum: "#181725",
  white: "#fff",
  black: "#000",
}

const variables = {
  // Colors
  color: {
    ...colors,

    primary: colors.turquoise,
    text: colors.plum,
    textLight: colors.lightPlum,
    textDark: colors.darkPlum,
  },

  // Typography
  font: {
    primary: "Poppins wf, sans-serif",
    secondary: "Acumin Pro ExtraCondensed wf, sans-serif",
    tertiary: "Frank Ruhl Libre wf, Georgia, serif",
    mono: "Roboto Mono wf, monospace",
  },
  basefontWeight: 400,
  baseFontSize: {
    phone: "15px",
    desktop: "17px",
  },
  baseLineHeight: 1.7,

  // Layout
  containerWidth: 1040,
  narrowContainerWidth: 700,
  wideContainerWidth: 1260,
  sidePadding: {
    phone: 20,
    tablet: 35,
    desktop: 50,
  },

  // Easing functions
  easing: {
    easeOutQuad: "cubic-bezier(0.25, 0.46, 0.45, 0.94)",
    easeInOutQuad: "cubic-bezier(0.455, 0.03, 0.515, 0.955)",
    easeOutQuart: "cubic-bezier(0.165, 0.84, 0.44, 1)",
    easeInOutQuart: "cubic-bezier(0.77, 0, 0.175, 1)",
    easeOutExpo: "cubic-bezier(0.19, 1, 0.22, 1)",
    easeInOutExpo: "cubic-bezier(1, 0, 0, 1)",
  },
}

export default variables
