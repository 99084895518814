import { createGlobalStyle } from "styled-components"
import { media } from "src/styles/breakpoints"

const Base = createGlobalStyle`
  body {
    background-color: ${(p) => p.theme.color.white};
    font-weight: ${(p) => p.theme.basefontWeight};
    font-size: ${(p) => p.theme.baseFontSize.phone};
    line-height: ${(p) => p.theme.baseLineHeight};
    font-family: ${(p) => p.theme.font.primary};
    color: ${(p) => p.theme.color.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    cursor: default;

    ${media.tablet} {
      font-size: ${(p) => p.theme.baseFontSize.desktop};
    }
  }
`

export default Base
