import React from "react"
import { ThemeProvider } from "styled-components"
import { Helmet } from "react-helmet"
import variables from "src/styles/variables"
import StylesReset from "src/styles/reset"
import StylesBase from "src/styles/base"
import Header from "src/components/Header/Header"
import Footer from "src/components/Footer/Footer"
import CookieAlert from "src/components/CookieAlert"

class BaseLayout extends React.Component {
  render() {
    const pathname = this.props.location.pathname
    const headerVariant = [
      "/",
      "/about/how-we-work",
      "/about/how-we-work/",
      "/about/mission-and-values",
      "/about/mission-and-values/",
      "/donate",
      "/donate/",
      "/get-involved/jobs",
      "/get-involved/jobs/",
      "/resources",
      "/resources/",
      "/subscribed",
      "/subscribed/",
      "/thank-you",
      "/thank-you/",
    ].includes(pathname)
      ? "light"
      : "dark"

    return (
      <ThemeProvider theme={variables}>
        <React.Fragment>
          <Helmet defer={false}>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0"
            />
          </Helmet>
          <StylesReset />
          <StylesBase />
          <Header variant={headerVariant} pathname={pathname} />
          <main>{this.props.children}</main>
          <Footer />
          <CookieAlert />
        </React.Fragment>
      </ThemeProvider>
    )
  }
}

export default BaseLayout
