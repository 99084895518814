import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import BaseButton from "src/components/Base/BaseButton"
import BaseIcon from "src/components/Base/BaseIcon"
import countryList from "../../../data/country-list"
import axios from "axios"

const NewsletterForm = ({
  prominent,
  onExtraFieldsShown,
  buttonText = "Send",
}) => {
  const [name, setName] = useState("")
  const [country, setCountry] = useState("")
  const [email, setEmail] = useState("")
  const [buttonLabel, setButtonLabel] = useState(buttonText)
  const [userSubscribed, setUserSubscribed] = useState(false)

  const button = prominent ? (
    <Button type="submit" label={buttonLabel} />
  ) : (
    <PlainButton type="submit" title={buttonLabel}>
      <ArrowIcon name="arrow-right" />
    </PlainButton>
  )

  function handleSubmit(event) {
    event.preventDefault()

    const nameSplit = name.split(" ")
    const firstName = nameSplit[0]
    // const lastName = nameSplit.length > 1 ? nameSplit.slice(-1) : ""
    // const pCountry = encodeURIComponent(country)

    setButtonLabel("Sending…")

    const petitionGuid = "a85fdf6e-45d0-45dd-96ec-983a0ccd16db"

    const signMutation = `
      mutation SignPetition($input: SignPetitionInput!) {
        SignPetition(input: $input) {
          success
          fullErrorMessages
          sign {
            signerName
            signerEmail
          }
        }
      }
    `

    const variables = {
      input: {
        petitionGuid,
        signerName: firstName || "unknown",
        signerEmail: email,
        newsletterConfirmed: true,
      },
    }

    const req = axios.post("https://crm.otwarteklatki.pl/graphql", {
      query: signMutation,
      variables,
    })
    req.then((resp) => {
      if (resp.data.data.SignPetition.success) {
        showThankYouMessage()
      } else {
        console.log("error", resp)
      }
    })
  }

  function showThankYouMessage() {
    setName("")
    setCountry("")
    setEmail("")
    setUserSubscribed(true)
  }

  if (onExtraFieldsShown) {
    onExtraFieldsShown(!!(name || country || email))
  }

  return (
    <Wrapper onSubmit={handleSubmit}>
      {!userSubscribed && (
        <>
          <ExtraFields
            hasBackground={!onExtraFieldsShown}
            className={`
          ${name || country || email ? "-active" : ""}
        `}
          >
            <FieldWrapper>
              <Input
                placeholder="Your name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                aria-label="Your name"
              />
              <FieldIcon name="user" source="feather" prominent={prominent} />
            </FieldWrapper>
            <FieldWrapper>
              <Select
                as="select"
                value={country}
                className={country ? "-filled" : ""}
                onChange={(e) => setCountry(e.target.value)}
                aria-label="Country"
              >
                <Option value="">Country</Option>
                {countryList.map((country, index) => (
                  <Option key={index}>{country}</Option>
                ))}
              </Select>
              <FieldIcon name="globe" source="feather" prominent={prominent} />
              <OptionalMark>optional</OptionalMark>
            </FieldWrapper>
          </ExtraFields>
          <FieldWrapper>
            <Input
              type="email"
              placeholder="Email address"
              required
              prominent={prominent}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              aria-label="Email address"
            />
            <FieldIcon name="send" source="feather" prominent={prominent} />
            {button}
          </FieldWrapper>
          <Paragraph>
            By signing up, you agree to our{" "}
            <Link to="/privacy-policy">Privacy policy</Link>.
          </Paragraph>
        </>
      )}
      {userSubscribed && (
        <ThankYouMessage>
          Thank you! To confirm your subscription, please click the link in the
          email we just sent you.
        </ThankYouMessage>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.form`
  position: relative;
`

const ExtraFields = styled.div`
  visibility: hidden;
  opacity: 0;
  width: 100%;
  padding-top: 70px;
  position: absolute;
  top: -190px;
  left: 0;
  transform: translateY(10px);
  background: ${(p) => (p.hasBackground ? "#fff" : "")};
  transition: 0.25s;

  &.-active {
    visibility: visible;
    opacity: 1;
    transform: none;
  }
`

const FieldWrapper = styled.div`
  margin-bottom: 10px;
  position: relative;

  ${ExtraFields} & {
    &:first-child {
      transform: translateY(10px);
      transition: 0.25s;
    }
  }

  ${ExtraFields}.-active & {
    &:first-child {
      transform: none;
    }
  }
`

const Input = styled.input`
  width: 100%;
  padding: 12px ${(p) => (p.prominent ? "125px" : "50px")} 11px 58px;
  border: 1px solid #cfdbdb;
  border-radius: 3px;
  color: #000;
  transition: 0.15s;
  transition-property: border-color, box-shadow;

  &::placeholder {
    color: rgba(116, 115, 125, 0.75);
  }

  &:focus {
    border-color: #3ab6da;
    box-shadow: 0 0 30px hsla(179, 64%, 58%, 0.1),
      0 0 5px hsla(179, 64%, 58%, 0.35);

    &::placeholder {
      color: rgba(116, 115, 125, 0.5);
    }
  }

  ${media.tablet} {
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

const Select = styled(Input)`
  color: rgba(116, 115, 125, 0.75);

  &.-filled {
    color: #000;
  }
`

const Option = styled.option``

const OptionalMark = styled.span`
  position: absolute;
  top: 0;
  right: 25px;
  line-height: 50px;
  font-size: 13px;
  /* color: ${(p) => p.theme.color.lightPlum}; */
  color: #8c9d9d;
  pointer-events: none;
`

const FieldIcon = styled(BaseIcon)`
  width: 18px;
  height: 18px;
  position: absolute;
  top: ${(p) => (p.name === "user" ? "15px" : "17px")};
  left: 20px;
  color: ${(p) => (p.prominent ? "#46cad8" : "#cfdbdb")};
  pointer-events: none;
  width: ${(p) => (p.name === "user" ? "19px" : "18px")};
  height: ${(p) => (p.name === "user" ? "19px" : "18px")};
`

const Button = styled(BaseButton)`
  position: absolute;
  top: 0;
  right: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`

const PlainButton = styled.button`
  width: 60px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
`

const ArrowIcon = styled(BaseIcon)`
  width: 13px;
  height: 13px;
  color: #076262;
`

const Paragraph = styled.p`
  margin-top: 20px;
  font-size: 12px;
  color: ${(p) => p.theme.color.textLight};

  a {
    border-bottom: 1px solid #cfdbdb;
  }
`

const ThankYouMessage = styled.p`
  max-width: 400px;
  font-weight: 600;
  font-size: 15px;
  color: #000;
`

export default NewsletterForm
