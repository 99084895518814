import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const DropdownMenu = ({
  name,
  items,
  type,
  currentValue,
  visible,
  showAll,
  onSelect,
  className,
}) => {
  const list =
    type === "links" ? (
      items.map(({ title, slug }, index) => (
        <Item key={index}>
          {isExternalLink(slug) ? (
            <StyledExternalLink href={slug} target="_blank">
              {title}
            </StyledExternalLink>
          ) : (
            <StyledLink
              to={`/${slug}`}
              partiallyActive={true}
              activeClassName="-active"
              onClick={(e) => hideMenu(e.target)}
            >
              {title}
            </StyledLink>
          )}
        </Item>
      ))
    ) : (
      <>
        {currentValue !== "All" && showAll && (
          <Option
            className="-empty"
            onClick={() => {
              onSelect("All")
            }}
          >
            All
          </Option>
        )}
        {items.map((value, index) => (
          <Option
            className={value === currentValue ? "-active" : ""}
            key={index}
            onClick={() => {
              onSelect(value)
            }}
          >
            {renderValue(value)}
          </Option>
        ))}
      </>
    )

  // hide parent menu on click
  function hideMenu(element) {
    const menuItem = element.closest("ul").closest("li")
    if (!menuItem) return
    menuItem.style.pointerEvents = "none"
    setTimeout(() => {
      menuItem.style.pointerEvents = ""
    }, 700)
  }

  function renderValue(value) {
    let result = value
    const split = value.split(" – ")
    if (split.length === 2) {
      result = (
        <>
          {split[0]} <small>{split[1]}</small>
        </>
      )
    }
    return result
  }

  function isExternalLink(linkUrl) {
    return linkUrl && linkUrl.startsWith('http');
  }

  return (
    <Wrapper className={`${visible ? "-visible" : ""} ${className}`}>
      {list}
    </Wrapper>
  )
}

const Wrapper = styled.ul`
  visibility: hidden;
  opacity: 0;
  /* width: calc(100% + 25px * 2); */
  width: 100%;
  min-width: 280px;
  max-height: 575px;
  overflow: auto;
  padding: 10px 0;
  position: absolute;
  z-index: 9;
  top: 50px;
  left: -25px;
  transform: translateY(-10px);
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 40px 60px rgba(0, 11, 33, 0.08);
  text-align: left;
  font-size: 15px;
  line-height: ${(p) => p.theme.baseLineHeight};
  white-space: nowrap;
  color: ${(p) => p.theme.color.text};
  transition: 0.25s ${(p) => p.theme.easing.easeOutExpo};
  outline: ${(p) => (p.visible ? "1px" : "0")} solid red;

  &.-visible {
    visibility: visible;
    opacity: 1;
    transform: none;
    transition-duration: 0.5s;
  }
`

const Item = styled.li``

const StyledLink = styled(Link)`
  display: block;
  padding: 6px 25px;

  &:hover {
    background: #d5f5f3;
    color: #000;
  }

  &.-active,
  &[aria-current] {
    font-weight: 500;
    color: #000;
  }
`

const StyledExternalLink = styled.a`
  display: block;
  padding: 6px 25px;

  &:hover {
    background: #d5f5f3;
    color: #000;
  }

  &.-active,
  &[aria-current] {
    font-weight: 500;
    color: #000;
  }
`

const Option = styled.li`
  padding: 6px 25px;

  small {
    margin-left: 7px;
    position: relative;
    top: -1px;
    font-weight: normal;
    font-size: 13px;
    color: #b4b3b8;
  }

  &:hover {
    background: #d5f5f3;
    color: #000;
  }

  &.-empty {
    color: #8c9d9d;
  }

  &.-active {
    font-weight: 500;
    color: #000;
  }
`

export default DropdownMenu
