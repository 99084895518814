import React from "react"
import sprites from "src/assets/sprites/sprites.svg"
import * as Icon from "react-feather"

const BaseIcon = ({ name, source, className }) => {
  if (name.indexOf("feather-") === 0) {
    name = name.replace("feather-", "")
    source = "feather"
  }

  const href = `${sprites}#${name}`

  const toPascalCase = (text) => {
    return text && text.replace(/(^\w|-\w)/g, clearAndUpper)
  }

  const clearAndUpper = (text) => {
    return text && text.replace(/-/, "").toUpperCase()
  }

  const IconTag = Icon[toPascalCase(name)]

  return source === "feather" ? (
    <IconTag className={className} style={{ fill: "none" }} />
  ) : (
    <svg className={className} data-name={name}>
      <use xlinkHref={href} href={href} />
    </svg>
  )
}

export default BaseIcon
