import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import BaseContainer from "src/components/Base/BaseContainer"
import BaseBrandLogo from "src/components/Base/BaseBrandLogo"
import FooterNavigation from "./FooterNavigation"
import NewsletterForm from "src/components/Form/NewsletterForm"
import SocialMediaIcons from "src/components/SocialMediaIcons"

const Footer = () => {
  const [paragraphVisible, setParagraphVisible] = useState(true)

  return (
    <Wrapper>
      <Container>
        <Column>
          <Link to="/" title="Anima International">
            <StyledLogo />
          </Link>
          <Paragraph visible={paragraphVisible}>
            We focus on what matters the most. Everything we do aims to reduce
            the suffering of animals to the greatest extent and in the most
            effective way possible.
          </Paragraph>
          <NewsletterForm
            onExtraFieldsShown={(shown) => {
              setParagraphVisible(!shown)
            }}
          />
        </Column>
        <Column>
          <FooterNavigation />
          <ExtraLinks>
            <SocialMediaIcons />
          </ExtraLinks>
        </Column>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.footer`
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  /* background-image: linear-gradient(#fff, #f4f6f7); */

  ${media.tablet} {
    padding-bottom: 50px;
  }
`

const Container = styled(BaseContainer)`
  ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`

const Column = styled.div`
  &:last-child {
    margin-top: 40px;
  }

  ${media.tablet} {
    width: 320px;

    &:first-child {
      padding-top: 4px;
    }

    &:last-child {
      width: 330px;
      margin-top: 95px;
    }
  }

  ${media.desktop} {
    &,
    &:last-child {
      width: 390px;
    }
  }
`

const StyledLogo = styled(BaseBrandLogo)`
  width: 100px;
  margin-bottom: 40px;
  color: ${(p) => p.theme.color.primary};

  ${media.tablet} {
    width: 120px;
    margin-bottom: 58px;
  }
`

const Paragraph = styled.p`
  opacity: ${(p) => (p.visible ? "" : 0)};
  max-width: 350px;
  margin-bottom: 40px;
  position: relative;
  font-size: 13px;
  line-height: 1.6;
  color: ${(p) => p.theme.color.lightPlum};
  transition: opacity 0.25s;

  ${media.tablet} {
    margin-bottom: 60px;
    font-size: 15px;
  }
`

const ExtraLinks = styled.div`
  position: relative;
`

export default Footer
