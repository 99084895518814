import React from "react"
import styled from "styled-components"
import { media } from "src/styles/breakpoints"
import BaseIcon from "src/components/Base/BaseIcon"

const defaultItems = [
  {
    title: "twitter",
    url: "https://twitter.com/anima_int",
  },
  {
    title: "facebook",
    url: "https://www.facebook.com/AnimaInter/",
  },
  {
    title: "linkedin",
    url: "https://www.linkedin.com/company/anima-international",
  },
]

const SocialMediaIcons = ({ media, className }) => {
  const items = media || defaultItems
  return (
    <List className={className}>
      {items.map(({ title, url }, index) => (
        <Item key={index}>
          <ItemLink
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            aria-label={title}
          >
            <Icon name={title} />
          </ItemLink>
        </Item>
      ))}
    </List>
  )
}

const List = styled.ul`
  display: flex;
`

const Item = styled.li`
  margin-right: 10px;

  ${media.desktop} {
    margin-right: 30px;
  }
`

const ItemLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: relative;
  border-radius: 50%;

  &::before,
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    pointer-events: none;
    transition: 0.15s;
  }

  &::before {
    border: 1px solid #cfdbdb;
  }

  &::after {
    opacity: 0;
    /* transform: scale(1.2); */
    background-image: linear-gradient(-20deg, #3ab6da, #50d9d6);
    /* box-shadow: 0 16px 16px rgba(3, 34, 33, 0.08); */
  }

  /* &[aria-label="twitter"]::after {
    background-image: linear-gradient(
      -20deg,
      rgb(15, 115, 218),
      rgb(25, 157, 238)
    );
  }

  &[aria-label="facebook"]::after {
    background-image: linear-gradient(
      -20deg,
      rgb(62, 100, 175) 0%,
      rgb(122, 143, 250)
    );
  }

  &[aria-label="linkedin"]::after {
    background-image: linear-gradient(
      -20deg,
      rgb(38, 102, 178),
      rgb(92, 161, 242)
    );
  } */

  &:hover {
    &::before {
      /* opacity: 0; */
      /* transform: scale(1.5); */
    }

    &::after {
      opacity: 1;
      /* transform: scale(1); */
    }
  }
`

const Icon = styled(BaseIcon)`
  width: 16px;
  height: 16px;
  position: relative;
  z-index: 1;
  color: #98a5a5;
  transition: 0.15s;

  ${ItemLink}:hover & {
    color: #fff;
  }
`

export default SocialMediaIcons
