import { createGlobalStyle } from "styled-components"

const Reset = createGlobalStyle`
  html {
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
  }

  * {
    &,
    &::after,
    &::before {
      box-sizing: inherit;
    }
  }

  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a,
  abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike,
  strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure,
  figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }

  body {
    line-height: 1;
  }

  strong, b {
    font-weight: 600;
  }

  ol, ul {
    list-style: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  button, input, textarea, select, option {
    margin: 0;
    padding: 0;
    background-color: transparent;
    border: none;
    appearance: none;
    box-shadow: none;
    font: inherit;

    &:focus,
    &:active {
      outline: none;
    }
  }

  input,
  textarea {
    &::placeholder {
      opacity: 1;
    }
  }

  a {
    font-family: inherit;
    text-decoration: none;
    color: inherit;

    &:focus,
    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  img,
  picture,
  svg {
    max-width: 100%;
    vertical-align: middle;
  }

  svg {
    fill: currentColor;
  }

  input,
  textarea,
  button {
    color: currentColor;
    font: inherit;
  }

  a,
  button:not(:disabled) {
    cursor: pointer;
  }

  button:disabled {
    cursor: not-allowed;
  }
`

export default Reset
