import React from "react"
import { Link } from "gatsby"
import styled, { css } from "styled-components"
import BaseIcon from "src/components/Base/BaseIcon"

const commonStyles = (props) => {
  let variantStyles = null
  let sizeStyles = null

  switch (props.variant) {
    case "outline":
      variantStyles = css`
        border: 1px solid #cfdbdb;
        line-height: 49px;
        font-weight: 500;
        /* color: rgba(0, 11, 33, 0.3); */
        color: #8c9d9d;
        transition: 0.15s;

        &:hover {
          border-color: ${(p) => p.theme.color.primary};
          color: ${(p) => p.theme.color.primary};
        }
      `
      break
    case "disabled":
      variantStyles = css`
        border: 1px solid #cfdbdb;
        cursor: default !important;
        line-height: 49px;
        font-weight: 500;
        /* color: rgba(0, 11, 33, 0.3); */
        color: #8c9d9d;
      `
      break
    default:
      variantStyles = css`
        background: linear-gradient(-20deg, #3ab6da, #50d9d6);
        transition: 0.15s;

        &::before {
          opacity: 0;
          content: "";
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background: linear-gradient(-20deg, #179cc2, #37d0cd);
          border-radius: 3px;
          transition: opacity 0.15s;
        }

        &:hover {
          &::before {
            opacity: 1;
          }
        }
      `
      break
  }

  switch (props.size) {
    case "medium":
      sizeStyles = css`
        min-width: 200px;
      `
      break
    case "small":
      sizeStyles = css`
        height: 30px;
        padding: 0 20px;
        font-size: 13px;
        line-height: 30px;
      `
      break
    default:
      break
  }

  return css`
    display: inline-block;
    height: 50px;
    padding: 0 35px;
    position: relative;
    border-radius: 3px;
    text-align: center;
    text-decoration: none;
    font-weight: 600;
    line-height: 50px;
    color: #fff;
    white-space: nowrap;
    user-select: none;

    ${variantStyles}
    ${sizeStyles}
  `
}

const StyledButton = styled.button((props) => {
  return css`
    ${commonStyles(props)}
  `
})

const StyledInternalLink = styled(Link)((props) => {
  return css`
    ${commonStyles(props)}
  `
})

const StyledExternalLink = styled.a((props) => {
  return css`
    ${commonStyles(props)}
  `
})

const StyledLabel = styled.span`
  position: relative;
`

const StyledIcon = styled(BaseIcon)``

class BaseButton extends React.Component {
  get buttonItems() {
    return (
      <React.Fragment>
        <StyledLabel>{this.props.label}</StyledLabel>
        {this.props.icon && (
          <StyledIcon name={this.props.icon} source="feather" />
        )}
      </React.Fragment>
    )
  }

  render() {
    const { onClick, to, ...rest } = this.props
    const external = to && to.includes("//")
    return to ? (
      external ? (
        <StyledExternalLink
          href={to}
          {...rest}
          target={this.props.download ? "" : "_blank"}
          rel="noopener noreferrer"
          onClick={onClick}
        >
          {this.buttonItems}
        </StyledExternalLink>
      ) : (
        <StyledInternalLink to={to} {...rest} onClick={onClick}>
          {this.buttonItems}
        </StyledInternalLink>
      )
    ) : (
      <StyledButton {...rest} onClick={onClick}>
        {this.buttonItems}
      </StyledButton>
    )
  }
}

export default BaseButton
